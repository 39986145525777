//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GetAppPopup from "./__GetAppPopup";
export default {
  name: 'GetAppPopupMain',
  components: {
    'app-get-app-popup': GetAppPopup
  },
  computed: {
    profileHasAutoPassword: function profileHasAutoPassword() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['player/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasAutoPassword;
    },
    nativeApp: function nativeApp() {
      return this.$store.$app.nativeApp;
    }
  },
  methods: {
    getApp: function getApp() {
      this.closePopup();

      if (this.nativeApp.showAllLinks()) {
        this.$store.dispatch('modals/setGetAppModalDesktop', {
          isOpen: true
        });
      } else {
        window.location = this.nativeApp.getLink();
      }
    },
    goToProfile: function goToProfile() {
      this.$router.push({
        name: 'profile'
      });
    },
    closePopup: function closePopup() {
      this.$store.dispatch('modals/setGetAppModal', {
        isOpen: false
      });
    }
  }
};